@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Montserrat:wght@300;400;500;600;700&display=swap');
@import "./scss/mixin.scss";
*{
  box-sizing: border-box;
}
body {
  font-family: 'Montserrat', sans-serif !important;
  overflow-x: hidden;
  background: #171635 !important;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Audiowide', cursive;
}
a{
  text-decoration: none !important;
}
ul{
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
}
img{
  max-width:100%;
}
.mb-5{
  margin-bottom: 50px;
}
.mainBg{
  background: url(../src/assets/images/mainBg.png) center top no-repeat;
  background-size: cover;
  padding-bottom: 100px;
  min-height: 100vh;
}
.gameBg{
  background: url(../src/assets/images/gameBg.jpg) center center no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: auto;
}
.container{
  max-width: 1400px !important;
  margin: 0 auto;
  padding: 0 15px;
  @include respond-to(media-xl) {
    max-width: 1200px !important;
    padding: 0 40px !important;
  }
  @include respond-to(media-md) {
    padding: 0 20px !important;
  }
}
