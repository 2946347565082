@import "../../../scss/mixin.scss";
.breadcrumBx{
    padding-bottom: 10px;
    @include respond-to(media-xs) {
        padding-bottom: 0;
        padding-top: 20px;
    }
    li.breadcrumb-item{
        color: #fff;
        font-size: 14px;
        a{
            color: #fff;
            &:hover{
                color: #0099ff;
                text-decoration: underline;
            }
        }
    }
}
.nftDetOuter{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include respond-to(media-sm) {
        display: block;
    }
    .nftdetLeft{
        width: 48%;
        position: relative;
        @include respond-to(media-sm) {
            width: 100%;
            margin-bottom: 20px;
        }
        i{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
.nftdetRight{
    width: 48%;
    padding: 30px;
    background: rgba(28, 28, 28, 0.6);
    border: 1px solid #545454;
    border-radius: 16px;
    @include respond-to(media-md) {
        padding: 15px;
    }
    @include respond-to(media-sm) {
        width: 100%;
    }
    .nftdetHd{
        padding-bottom: 20px;
        h2{
            font-size: 38px;
            color: #fff;
            @include respond-to(media-xs) {
                font-size: 26px;
            }
        }
        small{
            font-size: 16px;
            color: #858584;
            display: block;
            padding-bottom: 10px;
            @include respond-to(media-xs) {
                font-size: 14px;
            }
        }
        strong{
            font-size: 16px;
            color: #fff;
            font-weight: 700;
            padding-bottom: 10px;
            display: block;
            @include respond-to(media-xs) {
                font-size: 14px;
            }
        }
        span{
            font-size: 16px;
            color: #fff;
            @include respond-to(media-xs) {
                font-size: 14px;
            }
        }
    }
    .discCont{
        color: #fff;
        strong{
            font-size: 16px;
            display: block;
            padding-bottom: 5px;
            @include respond-to(media-xs) {
                font-size: 14px;
            }
        }
        span{
            font-size: 16px;
            display: block;
            padding-bottom: 5px;
        }
        p{
            font-size: 16px;
            line-height: 23px;
            @include respond-to(media-xs) {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}
.timerBg{
    background: rgba(49, 47, 49, 0.6);
    padding: 30px;
    border: 1px solid #545454;
    border-radius: 16px;
    margin-bottom: 20px;
    @include respond-to(media-md) {
        padding: 15px;
    }
    strong{
        color: #fff;
        display: block;
        font-size: 16px;
        padding-bottom: 10px;
        font-weight: 500;
    }
    .bidbuttons {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        button{
            width: 48%;
            height: 60px;
            font-size: 16px;
            background: transparent !important;
            color: #fff;
            border: #fff solid 1px;
            border-image: linear-gradient(to left, rgba(255, 214, 69, 0.7), rgba(234, 4, 173, 0.7), rgba(94, 74, 223, 0.7), rgba(82, 110, 242, 0.7), rgba(0, 237, 255, 0.7)) 1 !important;
            @include respond-to(media-md) {
                height: 50px;
            }
        }
    }
}
.timeCountdown{
    color: #fff;
    background: rgba(49, 47, 49, 0.6);
    border: #545454 solid 1px;
    border-radius: 16px;        
    padding: 20px;
    @include respond-to(media-xs) {
        padding: 15px 10px;
    }
    h2{
        display: flex;
        justify-content: space-between;
        font-size: 38px;
        @include respond-to(media-xs) {
            font-size: 26px;
        }
        span{
            width: 80px;
        }
    }
    h5{
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        @include respond-to(media-xs) {
            font-size: 13px;
        } 
        span{
            width: 80px;
        }
    }
}
