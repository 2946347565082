@import "../../../scss/mixin.scss";

.MidSecInner {
    max-width: 914px;
    margin: 0 auto;
    .totalValBx {
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        margin-top: 50px;
        border-radius: 16px;
        box-shadow: 1px 4px 0px rgba(0, 0, 0, 0.75);
        padding: 64px 115px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond-to(media-sm) {
            padding: 25px;
            margin-top: 75px;
        }
        @include respond-to(media-xs) {
            padding: 15px;
        }
        .totlValInn{
            label {
                display: block;
                font-size: 24px;
                color: #fff;
                padding-bottom: 5px;
                @include respond-to(media-sm) {
                    font-size: 20px;
                }
                @include respond-to(media-xs) {
                    font-size: 15px;
                }
            }
            strong{
                font-size: 24px;
                font-weight: 600;
                color: #fff;
                @include respond-to(media-sm) {
                    font-size: 20px;
                }
                @include respond-to(media-xs) {
                    font-size: 15px;
                }
            }
            span{
                display: block;
                font-size: 22px;
                color: #fff;
                @include respond-to(media-xs) {
                    font-size: 15px;
                }
                img{
                    width: 33px;
                    display: inline-block;
                    margin-right: 5px;
                    @include respond-to(media-xs) {
                        width: 20px;
                    }
                }
            }
        }
    }
    .gameListOuetr{
        padding-top: 40px;
        @include respond-to(media-xs) {
            padding-top: 20px;
        }
        .ListBg_Out{
            background: url(../../../assets/images/gamelistBg.png) center center no-repeat;
            background-size: 100% 100%;
            padding: 25px 0;
            @include respond-to(media-sm) {
                padding: 20px 0;
            }
            &:hover{
                background: url(../../../assets/images/gamelistBg-hover.png) center center no-repeat;
            }
            .gamelist{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 10px;
                color: #fff;
                padding: 16px;
                @include respond-to(media-xs) {
                    display: block;
                }
                .gamelist_Left{
                    small{
                        display: block;
                        padding-bottom: 20px;
                        font-size: 12px;
                        @include respond-to(media-xs) {
                            padding-bottom: 10px;
                        }
                    }
                    strong{
                        display: block;
                        font-size: 14px;
                        padding-bottom: 5px;
                    }
                    span{
                        display: block;
                        @include respond-to(media-xs) {
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

.gamelist_Right img {
    max-width: 140px;
    border-radius: 10px;
}