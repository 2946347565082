@import "../../../scss/mixin.scss";
header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    @include respond-to(media-sm) {
        display: block;
        padding-top: 10px;
        position: fixed;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        z-index: 99;
    }
    .headLeft{
        @include respond-to(media-sm) {
            padding-left: 15px;
        }
        a{
            display: inline-block;
            @include respond-to(media-sm) {
                width: 130px;
            }
            @include respond-to(media-sm) {
                margin-bottom: 15px;
            }
        }
    }
    .headRight{
        button{
            // width: 210px;
            height: 47px;
            border: #fff solid 1px;
            border-radius: 5px;
            font-size: 17px;
            color: #fff;
            margin-left: 25px;
            background: transparent !important;
            transition: border-image 0.2s ease-in-out;
            border-image: linear-gradient(to left, rgba(255, 214, 69, 0.7), rgba(234, 4, 173, 0.7), rgba(94, 74, 223, 0.7), rgba(82, 110, 242, 0.7), rgba(0, 237, 255, 0.7)) 1 !important;
            transition: all 0.5s ease-in-out;
            @include respond-to(media-lg) {
                margin-left: 7px;
            }
            @include respond-to(media-md) {
                width: auto;
                margin-left: 7px;
                font-size: 13px;
            }
            @include respond-to(media-sm) {
                font-size: 14px;
            }
            @include respond-to(media-xs) {
                margin-bottom: 10px;
                font-size: 12px;
                padding: 0 7px;
                height: 35px;
             }
            &:hover{
                transform: scale(1.03) perspective(1000px);
                transition: all 0.5s ease-in-out;
            }
            a {
                color:#fff
            }
        }
    }
}
.navOuter{
    display: inline-block;
    @include respond-to(media-sm) {
        position: absolute;
        top: 5px;
        right: 10px;
    }

    button.Navbtn {
        width: 44px;
        height: 40px;
        border: 0 !important;
        padding: 0;
        font-size: 33px;
        line-height: 37px;
        margin-left: 10px;
        background: transparent !important;
        @include respond-to(media-sm) {
            margin-left: 3px;
        }
       
    }
    .NavListOuter {
        position: fixed;
        right: -285px;
        top: 0;
        bottom: 0;
        width: 250px;
        transition: all 0.5s ease-in-out;
        z-index: 999999;
        .card{
            border: 0;
            background: #0a0a0ac9;
            border-radius: 0;
            height: 100vh;
            .NavList{
                padding-top: 10px;
                ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li{
                        a{
                            display: block;
                            color: #fff;
                            padding: 15px 0;
                            border-bottom: #444444a8 solid 1px;
                            font-size: 16px;
                            &:hover{
                                color: #e307af;
                            }
                        }
                        &:last-child{
                            a{
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .NavListOuter.collapse.show {
        right: 0;
        transition: all 0.5s ease-in-out;
    }
}
button.NavClose {
    position: absolute;
    right: 14px;
    width: 35px !important;
    height: 35px !important;
    background: #000 !important;
    top: 7px;
    line-height: 19px;
}
.aftLoginBtn {
    border: 1px solid #F3D453 !important;
    border-radius: 12px !important;
    height: 36px !important;
    font-size: 12px !important;
    width: auto !important;
    padding: 0 10px 0 15px;
    line-height: 24px;
    
    i{
        background: linear-gradient(90deg, #69C6E1 0%, #69C6E1 94.27%);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        margin-right: 7px;
    }
    svg{
        font-size: 16px;
    }
}
.NavListOuter .mobnavBtns{
    >  button{
        display: block;
        color: #fff !important;
        padding: 15px 0;
        border: 0 !important;
        border-top: #444444a8 solid 1px !important;
        font-size: 16px !important;
        text-align: left;
        border-radius: 0 !important;
        border-image: none !important;
        text-transform: uppercase;
    }
}


button#webModal {
    background: #3396ff !important;
    border: #3396ff solid 1px !important;
    border-radius: 0 !important;
    &:hover{
        background: #2e87e5 !important;
        border: #2e87e5 solid 1px !important;
    }
}