@import "../../../scss/mixin.scss";

.trans_graph{
    margin-top: 80px;
    background: rgba(28, 28, 28, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding: 50px 130px;
    @include respond-to(media-md) {
        padding: 50px;
    }
    @include respond-to(media-xs) {
        padding: 20px;
        margin-top: 75px;
    }
    .transValueBx{
        padding: 0;
        background: transparent;
        margin-top: 0;
        box-shadow: none;
        margin-bottom: 30px;
    }
}
.transValue{
    background: rgba(28, 28, 28, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding: 50px 50px 10px 50px;
    margin-top: 15px;
    @include respond-to(media-sm) {
        padding: 20px 20px 10px 20px;
    }
    @include respond-to(media-xs) {
        padding: 20px 20px 10px 20px;
    }
    .valueHd{
        font-size: 20px;
        color: #fff;
        font-weight: 600;
         @include respond-to(media-xs) {
            font-size: 16px;
        }
    }
    .transtable{
        @include respond-to(media-xs) {
            overflow: auto;
        }
        table{
            width: 100%;
            @include respond-to(media-xs) {
                min-width: 600px;
            }
            tr{
                th{
                    padding-bottom: 10px;
                    color: #878787;
                    font-size: 14px;
                    font-weight: 400;
                    @include respond-to(media-xs) {
                        font-size: 12px;
                        padding-bottom: 5px;
                    }
                }
            }
            tr{
                td{
                    border-bottom: #fff dashed 1px;
                    padding: 10px 0;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    color: #fff;
                    @include respond-to(media-xs) {
                        font-size: 14px;
                        padding: 10px 0;
                    }
                }
                &:last-child{
                    td{
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}
.paginationBx {
    padding: 15px 0;
    text-align: right;
    ul{
        display: inline-flex;
        li{
            margin-right: 10px;
            a {
                background: transparent !important;
                font-size: 20px;
                color: #fff !important;
                border: 0;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                padding: 0;
                display: inline-block;
            }
            &:first-child, &:last-child{
                a{
                    span{
                        font-size: 20px;
                        line-height: 0;
                    }
                }
            }
        }
        li.active{
            border: #fff solid 1px;
            border-radius: 50%;
        }
        .previous{
            a{
                // background: #2068eb !important;
                font-size: 16px;
                color: #fff;
                height: auto;
                text-align: center;
                line-height: 30px;
                padding: 0 15px;
                // border: #2068eb solid 1px;
                display: inline-block;
                width: auto;
                text-transform: capitalize;
                border-radius: 5px;
            }
        }
        // li.previous.disabled{
        //     a{
        //         opacity: 0.8;
        //     }
        // }
        .next{
            a{
                // background: #2068eb  !important;
                font-size: 16px;
                color: #fff;
                height: auto;
                text-align: center;
                line-height: 30px;
                padding: 0 15px;
                // border: #2068eb solid 1px;
                display: inline-block;
                width: auto;
                text-transform: capitalize;
                border-radius: 5px;
            }
        }
        .active{
            a.page-link {
                border: #fff solid 1px !important;
                border-radius: 50%;
            }
        }
    }
}
.nodata {
    font-size: 16px;
    color: #fff;
    text-align: center;
    i{
        display: block;
        padding-bottom: 20px;
    }
    strong{
        display: block;
        font-size: 18px;
    }
}
.graphImg{
    img {
        width: 100%;
    }
}
.transHdOuter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    @include respond-to(media-sm) {
        display: block; 
    }
    .transFltr{
        .dateInpBx{
            position: relative;
            display: inline-block;
            vertical-align: top;
            max-width: 300px;
            @include respond-to(media-xs) {
                display: block; 
                padding-bottom: 10px;
                max-width: 100%;
                padding-top: 5px;
            }
            .dateInp{
                width: 100%;
                height: 40px;
                background: #fff;
                border-radius: 5px;
                border: 0;
                padding: 0 10px;
                @include respond-to(media-sm) {
                    font-size: 13px;
                }
            }
            svg{
                position: absolute;
                right: 10px;
                top: 13px;
            }
        }
        .srchInpBx{
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            position: relative;
            max-width: 300px;
            @include respond-to(media-sm) {
                max-width: 159px;
            }
            @include respond-to(media-xs) {
                margin-left: 0;
                max-width: calc(100% - 60px);
            }
            .srchInp{
                width: 100%;
                height: 40px;
                background: #fff;
                border-radius: 5px;
                border: 0;
                padding: 0 25px 0 10px;
            }
            svg{
                position: absolute;
                right: 5px;
                font-size: 20px;
                top: 9px;
            }
        }
    }
}
.refreshBtn{
    display: inline-block;
    margin-left: 10px;
    button{
        background: transparent !important;
        border: #ccc solid 1px !important;
        height: 40px;
        font-size: 23px;
        padding: 0;
        width: 40px;
        line-height: 35px;
    }
}