
@import "../../../scss/mixin.scss";
.MidSecBx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    @include respond-to(media-xl) {
        padding-top: 20px;
    }
    @include respond-to(media-xl) {
        padding-top: 55px;
    }
    @include respond-to(media-sm) {
        flex-direction: column-reverse;
    }
    .homeLeft{
        font-size: 18px;
        width:calc(100% - 700px);
        @include respond-to(media-lg) {
            width:calc(100% - 500px);
        }
        @include respond-to(media-md) {
            width:calc(100% - 440px);
        }
        @include respond-to(media-sm) {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 30px;
            text-align: center;
        }
        h2{
            font-size: 65px;
            text-transform: uppercase;
            color: #fff;
            padding-bottom: 20px;
            line-height: 85px;
            @include respond-to(media-xl) {
                font-size: 48px;
                line-height: 58px;
            }
            @include respond-to(media-md) {
                font-size: 30px;
                line-height: 40px;
            }
            @include respond-to(media-xs) {
                text-align: left;
            }
        }
        p{
            font-size: 22px;
            color: #fff;
            line-height: 35px;
            padding-bottom: 60px;
            @include respond-to(media-xl) {
                font-size: 18px;
            }
            @include respond-to(media-md) {
                font-size: 16px;
                line-height: 26px;
                padding-bottom: 40px;
            }
            @include respond-to(media-sm) {
                padding-bottom: 20px;
            }
        }
        
        a{
            display: inline-block;
            line-height: 30px;
            padding: 10px 30px;
            font-size: 20px;
            border: #54F6FF solid 1px;
            border-radius: 5px;
            color: #fff;
            width: 270px;
            text-align: center;
            transition: border-image 0.2s ease-in-out;
            border-image: linear-gradient(to left, rgba(255, 214, 69, 0.7), rgba(234, 4, 173, 0.7), rgba(94, 74, 223, 0.7), rgba(82, 110, 242, 0.7), rgba(0, 237, 255, 0.7)) 1 !important;
        
            @include respond-to(media-sm) {
                display: inline-block;
                line-height: 30px;
                padding: 7px 26px;
                font-size: 17px;
                width: 200px;
            }
            &:hover{
                transform: scale(1.03) perspective(1000px);
                transition: all 0.5s ease;
            }
        }
    }
    .metamaskBx {
        text-align: center;
        i{
            display: block;
            img{
                @include respond-to(media-lg) {
                    max-width: 220px;
                }
                @include respond-to(media-xs) {
                    max-width: 170px;
                }
            }
        }
        button{
            margin-top: 40px;
            height: 50px;
            width: 100%;
            background: transparent;
            border: #fff solid 1px;
            border-radius: 16px;
            font-size: 18px;
            @include respond-to(media-xs) {
                border-radius: 8px;
                font-size: 16px;
                padding: 0 20px;
                width: auto;
            }
            &:hover{
                background: #e307af;
                border: #e307af solid 2px;
                color: #fff;
            }
            a{
                color: #fff;
                &:hover{
                    color: #fff;
                }
            }
        }
    }
  }
  
  .homeRight{
    background: url(.././../../assets/images/transBg.png) left top no-repeat;
    background-size: 100% 100%;
    border-radius: 40px;
    width: 613px;
    height: 647px;
    padding: 87px 100px 0 100px;
    margin-right: -53px;
    @include respond-to(media-xxl) {
        margin-right: 0;
    }
    @include respond-to(media-lg) {
        width:500px;
        height: 550px;
        margin-right: -12px;
    }
    @include respond-to(media-md) {
        width:440px;
        height: 500px;
        margin-right: -12px;
        padding: 60px 70px 0 70px;
    }
    @include respond-to(media-sm) {
        width: 100%;
        margin-right:0;
        padding: 70px 80px 0 80px;
    }
    @include respond-to(media-xs) {
        width: 100%;
        margin-right:0;
        padding: 70px 50px 0 50px;
        height: auto;
        min-height: 430px;
    }
}

.confamation_modal{
    .modal-content{
        background: #000;
        color: #fff;
        .modal-body {
            color: #fff !important;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
        }
        .modal-footer {
            text-align: center;
            display: block;
            padding: 50px 0;
            .yesBtn{
                background: #0099ff;
                width: 80px;
                border: 0;
                margin: 0 10px;
                height: 40px;
                font-weight: 500;
                text-transform: uppercase;
            }
            .noBtn{
                width: 80px;
                height: 40px;
                border: 0;
                background: #ff0000;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }
}
.midBg{
    background: #0A0A3F;
    padding-top: 50px;
    position: relative;
    @include respond-to(media-lg) {
        padding-top: 0;
    }
        &:after{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: -150px;
            background: linear-gradient(180deg, rgba(20, 23, 48, 0) 49.69%, #0A0A3F 100%);
            height: 150px;
        }
    .midBgInn{
        background: url(../../../assets/images/GameSphere-main-back-section.png) left top repeat-y;
        background-size: 100%;
        .gameWorld{
            padding: 100px 0;
            @include respond-to(media-lg) {
                padding: 50px 0;
            }
            @include respond-to(media-md) {
                padding:0;
            }
            @include respond-to(media-xs) {
                padding: 0 0 50px 0;
            }
            .gamevideoInn{
                background: url(../../../assets/images/Group68.png) right top -100px no-repeat;
                position: relative;
                padding-top: 112px;
                @include respond-to(media-xl) {
                    padding-top: 50px;
                }
                @include respond-to(media-sm) {
                    background: transparent;
                }
                &::before{
                    content: '';
                    background: url(../../../assets/images/shape1.png) left top no-repeat;
                    position: absolute;
                    left: 0px;
                    top: 50px;
                    transform: rotate(-180deg);
                    width: 240px;
                    height: 245px;
                    z-index: 99;
                    @include respond-to(media-sm) {
                        width: 150px;
                        height: 150px;
                        background-size: 100%  100%;
                    }
                }
                &::after{
                    content: '';
                    background: url(../../../assets/images/shape1.png) left top no-repeat;
                    position: absolute;
                    right: 0px;
                    bottom: -63px;
                    width: 240px;
                    height: 245px;
                    @include respond-to(media-sm) {
                        width: 150px;
                        height: 150px;
                        background-size: 100% 100%;
                    }
                }
                .gamevidBx{
                    max-width: 1360px;
                    width: 100%;
                    margin: 0 auto;
                    position: relative;
                    @include respond-to(media-xl) {
                        padding: 0 80px;
                        max-width: 1100px;
                    }
                    @include respond-to(media-sm) {
                        padding: 0 20px;
                        max-width: 1100px;
                    }
                    .gameHd {
                        text-align: right;
                        top: -100px;
                        position: absolute;
                        right: -90px;
                        z-index: 99;
                        @include respond-to(media-xxl) {
                            right: -20px;
                        }
                        @include respond-to(media-xl) {
                            right: 10px;
                            top: -90px;
                        }
                        @include respond-to(media-lg) {
                            top: -75px;
                        }
                        @include respond-to(media-md) {
                            top: -47px;
                        }
                        @include respond-to(media-sm) {
                            top: -37px;
                        }
                        img{
                            @include respond-to(media-xl) {
                                max-width: 1000px;
                            }
                            @include respond-to(media-lg) {
                                max-width: 800px;
                            }
                            @include respond-to(media-md) {
                                max-width: 500px;
                            }
                            @include respond-to(media-sm) {
                                max-width: 380px;
                            }
                            @include respond-to(media-xs) {
                                max-width: 275px;
                            }
                        }
                    }
                    
                    .gridVideo{
                        height: 735px;
                        background: #000;
                        border-radius: 30px;
                        position: relative;
                        @include respond-to(media-xl) {
                            height: 500px;
                        }
                        @include respond-to(media-md) {
                            height: 500px;
                        }
                        @include respond-to(media-sm) {
                            height: 330px;
                        }
                        @include respond-to(media-xs) {
                            height: 300px;
                        }
                        .video-react {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            padding-top: 0 !important;
                            video.video-react-video {
                                width: 100%;
                                height: 100%;
                            }
                            .video-react-time-control {
                                display: none;
                            }
                            .video-react-progress-control {
                                display: none;
                            }
                        }
                        > button{
                            position:absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            background: transparent !important;
                            border: 0;
                            display: none;
                            img{
                                @include respond-to(media-md) {
                                height: 150px;
                                }
                                @include respond-to(media-md) {
                                    height: 110px;
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }
}

.ecosystem{
    padding: 100px 0;
    background: url(../../../assets/images/ecoback-shadow.png) center center no-repeat;
    @include respond-to(media-xs) {
        padding: 50px 0;
    }
    .eco_container{
        max-width: 1500px;
        margin: 0 auto;
        @include respond-to(media-xl) {
            padding: 0 40px;
        }
        @include respond-to(media-sm) {
            padding: 0 20px;
        }
    }
    .ecoHd{
        font-weight: 400;
        font-size: 60px;
        line-height: 76px;
        text-align: center;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        color: #FFFFFF;
        padding-bottom: 80px;
        @include respond-to(media-lg) {
            font-size: 48px;
            line-height: 60px;
            padding-bottom: 50px;
        }
        @include respond-to(media-sm) {
            font-size: 36px;
            line-height: 50px;
            padding-bottom: 50px;
        }
        @include respond-to(media-xs) {
            font-size: 24px;
            line-height: 35px;
            padding-bottom: 10px;
        }
    }
    .ecolistOuter{
        .ecoshape{
            margin: 0 auto;
            max-width: 1147px;
            @include respond-to(media-xl) {
                max-width: 1010px;
            }
            @include respond-to(media-lg) {
                max-width: 820px;
            }
            @include respond-to(media-md) {
                max-width: 590px;
            }
        }
        .ecoList1 {
            display: flex;
            justify-content: space-between;
            align-items: start;
            
            .ecoBx1 {
                width: 25%;
                text-align: center;
                color: #FFFFFF;
                padding: 0 10px;
                small{
                    display: block;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    text-transform: uppercase;
                    min-height: 100px;
                    @include respond-to(media-lg) {
                        font-size: 12px;
                    }
                    @include respond-to(media-md) {
                        font-size: 10px;
                        line-height: 17px;
                    }
                }
                strong{
                    display: block;
                    font-weight: 800;
                    font-size: 30px;
                    line-height: 37px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    padding: 30px 0;
                    @include respond-to(media-xl) {
                        font-size: 26px;
                    }
                    @include respond-to(media-lg) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    @include respond-to(media-md) {
                        font-size: 16px;
                        min-height: 130px;
                        padding: 20px 0;
                    }
                }
                i{
                    display: block;
                    img{
                        @include respond-to(media-lg) {
                            width: 85px;
                        }
                    }
                }
            }
        }
        .ecoBx2{
            width: 25%;
            text-align: center;
            color: #FFFFFF;
            padding: 20px 10px 0 10px;
            small{
                display: block;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.025em;
                text-transform: uppercase;
                @include respond-to(media-xl) {
                    font-size: 13px;
                }
                @include respond-to(media-md) {
                    font-size: 10px;
                    line-height: 17px;
                }
            }
            strong{
                display: block;
                font-weight: 800;
                font-size: 30px;
                line-height: 37px;
                text-align: center;
                letter-spacing: 0.025em;
                text-transform: uppercase;
                color: #FFFFFF;
                padding: 30px 0;
                min-height: 171px;
                @include respond-to(media-xl) {
                    font-size: 26px;
                }
                @include respond-to(media-lg) {
                    font-size: 20px;
                    line-height: 30px;
                    min-height: 150px;
                }
                @include respond-to(media-md) {
                    font-size: 16px;
                    min-height: 130px;
                    padding: 20px 0;
                }
            }
            i{
                display: block;
                img{
                    @include respond-to(media-lg) {
                        width: 85px;
                    }
                }
            }
        }
    }
}

/*-----*/
.keyfeatOuter{
    padding: 140px 0;
    @include respond-to(media-xl) {
        padding: 0 0 140px 0;
    }
    @include respond-to(media-sm) {
        padding: 0 0 70px 0;
    }
    .featHd{
        background: url(../../../assets/images/shape1-old.png) right center no-repeat;
        padding: 72px 0;
        max-width: 1050px;
        margin: 0 auto;
        @include respond-to(media-md) {
            background-size: 320px;
        }
        @include respond-to(media-sm) {
            background-size: 236px;
            padding: 0 0 10px 0;
        }
        h2{
            font-weight: 400;
            font-size: 80px;
            line-height: 102px;
            letter-spacing: 0.025em;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            @include respond-to(media-md) {
                font-size: 56px;
            }
            @include respond-to(media-sm) {
                font-size: 40px;
            }
            @include respond-to(media-xs) {
                font-size: 28px;
            }
        }
    }
    .featList{
        padding-top: 10px;
        ul{
            li{
                @include respond-to(media-sm){
                    padding-bottom: 20px;
                    padding-top: 20px;
                    position: relative;
                }
                &::after{
                    @include respond-to(media-sm){
                        height: 1px;
                        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2bbdfc+0,ff5151+98 */
                        background: #2bbdfc; /* Old browsers */
                        background: -moz-linear-gradient(left,  #2bbdfc 0%, #ff5151 98%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(left,  #2bbdfc 0%,#ff5151 98%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to right,  #2bbdfc 0%,#ff5151 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2bbdfc', endColorstr='#ff5151',GradientType=1 ); /* IE6-9 */
                        width: 100%;    
                        content: ''; 
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
                .featList1{
                    position: relative;
                    padding-left: 90px;
                    margin-bottom: 50px;
                    @include respond-to(media-lg){
                        padding-left: 70px;
                    }
                    @include respond-to(media-sm){
                        padding-bottom: 0;
                        margin-bottom: 0;
                        padding-left: 40px;
                    }
                    h2{
                        font-weight: 400;
                        font-size: 80px;
                        line-height: 102px;
                        letter-spacing: 0.025em;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        position: absolute;
                        left: 0;
                        top: 5px;
                        @include respond-to(media-lg) {
                            top: 0;
                            font-size: 70px;
                        }
                        @include respond-to(media-sm) {
                            font-size: 45px;
                            line-height: 50px;
                        }
                    }
                    p{
                        font-weight: 400;
                        font-size: 40px;
                        line-height: 50px;
                        margin-bottom: 10px !important;
                        color: #FFFFFF;
                        position: relative;
                        @include respond-to(media-xl) {
                            font-size: 30px;
                        }
                        @include respond-to(media-lg) {
                            font-size: 30px;
                        }
                        @include respond-to(media-md){
                            font-size: 21px;
                        }
                        @include respond-to(media-sm){
                            font-size: 15px;
                            line-height: 30px;
                        }
                        @include respond-to(media-xs){
                            font-size: 18px;
                        }
                        span{
                            padding-right: 10px;
                        }
                        i{
                            height: 2px;
                            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2bbdfc+0,ff5151+98 */
                            background: #2bbdfc; /* Old browsers */
                            background: -moz-linear-gradient(left,  #2bbdfc 0%, #ff5151 98%); /* FF3.6-15 */
                            background: -webkit-linear-gradient(left,  #2bbdfc 0%,#ff5151 98%); /* Chrome10-25,Safari5.1-6 */
                            background: linear-gradient(to right,  #2bbdfc 0%,#ff5151 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2bbdfc', endColorstr='#ff5151',GradientType=1 ); /* IE6-9 */
                            width: 100%;
                             @include respond-to(media-sm){
                                 display: none;
                             }
                        }
                    }
                }
                .featList2{
                    padding-right: 90px;
                    margin-bottom: 50px;
                    text-align: right;
                    position: relative;
                    @include respond-to(media-sm){
                        padding-bottom: 0;
                        margin-bottom: 0;
                        padding-right: 50px;
                    }
                    h2{
                        font-weight: 400;
                        font-size: 80px;
                        line-height: 102px;
                        letter-spacing: 0.025em;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        position: absolute;
                        right: 0;
                        top: 5px;
                        @include respond-to(media-lg) {
                            top: 0;
                            font-size: 70px;
                        }
                        @include respond-to(media-sm) {
                            font-size: 45px;
                            line-height: 50px;
                        }
                    }
                    p{
                        font-weight: 400;
                        font-size: 40px;
                        line-height: 50px;
                        margin-bottom: 10px !important;
                        color: #FFFFFF;
                        position: relative;
                        @include respond-to(media-xl) {
                            font-size: 30px;
                        }
                        @include respond-to(media-lg) {
                            font-size: 30px;
                        }
                        @include respond-to(media-md) {
                            font-size: 21px;
                        }
                        @include respond-to(media-sm){
                            font-size: 15px;
                            line-height: 30px;
                            text-align: right;
                            display: block;
                        }
                        @include respond-to(media-xs){
                            font-size: 18px;
                        }
                        span{
                            @include respond-to(media-sm){
                                display: block;
                                text-align: right;
                            } 
                        }
                        i{
                            height: 2px;
                            background: #2bbdfc; /* Old browsers */
                            background: -moz-linear-gradient(left,  #2bbdfc 0%, #ff5151 98%); /* FF3.6-15 */
                            background: -webkit-linear-gradient(left,  #2bbdfc 0%,#ff5151 98%); /* Chrome10-25,Safari5.1-6 */
                            background: linear-gradient(to right,  #2bbdfc 0%,#ff5151 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2bbdfc', endColorstr='#ff5151',GradientType=1 ); /* IE6-9 */
                            width: 100%;
                            max-width: 46%;
                            @include respond-to(media-sm){
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.featBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/*-----*/
.fillShapeBx{
    position: relative;
    margin-top: 200px;
    padding: 150px 0 250px 0;
    @include respond-to(media-xxl) {
        padding: 0 0 250px 0;
    }
    @include respond-to(media-lg) {
        margin-top: 150px;
        padding-bottom: 150px;
    }
    @include respond-to(media-xs) {
        margin-top: 120px;
        padding-bottom: 55px;
    }
    &:after{
        content: '';
        position: absolute;
        right: 0px;
        bottom: -50px;
        background: url(../../../assets/images/fill-shape.png) right no-repeat;
        height: 314px;
        width: 485px;
        @include respond-to(media-lg) {
            height: 200px;
            width: 300px;
            background-size: 100% 100%;
        }
        @include respond-to(media-sm) {
            height: 160px;
            width: 200px;
        }
        @include respond-to(media-xs) {
            height: 120px;
            width: 150px;
            bottom: -70px;
        }
    
    }
    &:before{
        content: '';
        position: absolute;
        left: 0px;
        top: 0;
        background: url(../../../assets/images/fillshape1.png) right no-repeat;
        height: 314px;
        width: 360px;
        @include respond-to(media-xxl) {
            top: -300px;
        }
        @include respond-to(media-lg) {
            height: 220px;
            width: 300px;
            background-size: 100% 100%;
            top: -250px;
        }
        @include respond-to(media-sm) {
            height: 160px;
            width: 200px;
            top: -170px;
        }
        @include respond-to(media-xs) {
            height: 120px;
            width: 150px;
            top: -130px;
        }
    }
    .innerCont{
        font-size: 38px;
        line-height: 59px;
        text-align: center;
        color: #fff;
        padding: 0 100px;
        position: relative;
        @include respond-to(media-xl) {
            font-size: 36px;
            line-height: 55px;
        }
        @include respond-to(media-lg) {
            font-size: 32px;
            line-height: 45px;
            padding: 0 50px;
        }
        @include respond-to(media-lg) {
            font-size: 24px;
            line-height: 35px;
            padding: 0px;
        }
        @include respond-to(media-xs) {
            font-size: 18px;
            line-height: 27px;
        }
    }
}

/*-------How It Works-------*/
.ecosystem.howitOuter{
    background: transparent;
    padding: 100px 0 50px 0;
    .howitBx{
        text-align:center;
    }
}

/*------------Mission-------------*/
.missionOuter{
    background: url(../../../assets/images/Group68.png) left top no-repeat;
    position: relative;
    height: 1335px;
    @include respond-to(media-xl) {
        height: 1100px;
    }
    @include respond-to(media-md) {
        height: 860px;
        background-size: 100% 100%;
    }
    @include respond-to(media-xs) {
        height: auto;
        background-size: 100% 100%;
        padding-bottom: 50px;
    }
    &:after{
        content: '';
        background:url(../../../assets/images/mission-shape.png) right top no-repeat;
        right: 0px;
        top: 0;
        width: 982px;
        height: 1335px;
        position: absolute;
        @include respond-to(media-xl) {
            top: 100px;
            width: 780px;
            height: 1000px;
            background-size: 100%;
        }
        @include respond-to(media-md) {
            width: 650px;
            opacity: 0.3;
        }
        @include respond-to(media-sm) {
            width: 100%;
        }
    }
    .missionLeft{
        max-width: 900px;
        padding-top: 200px;
        opacity: 1;
        position: relative;
        z-index: 9;
        @include respond-to(media-xl) {
            max-width: 740px;
        }
        @include respond-to(media-lg) {
            max-width: 510px;
        }
        @include respond-to(media-md) {
            max-width: 100%;
        }
        @include respond-to(media-sm) {
            padding-top: 0;
        }
        h2{
            font-size: 60px;
            line-height: 76px;
            color: #fff;
            padding-bottom: 30px;
            text-transform: uppercase;
            @include respond-to(media-sm) {
                font-size: 48px;
                line-height: 60px;
                padding-bottom: 0;
            }
            @include respond-to(media-xs) {
                font-size: 30px;
                line-height: 45px;
            }
        }
        p{
            font-size: 20px;
            line-height: 31px;
            color: #fff;
            padding-bottom: 40px;
            font-weight: 300;
            @include respond-to(media-xs) {
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                padding-bottom: 20px;
            }
        }
        ul{
            li{
                background: url(../../../assets/images/mission-arrow.png) left top 5px no-repeat;
                font-size: 20px;
                line-height: 31px;
                font-weight: 300;
                color: #fff;
                padding-left: 30px;
                padding-bottom: 20px;
                 @include respond-to(media-xs) {
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                padding-bottom: 20px;
            }
            }
        }
    }
}
/*------------About-------------*/
.aboutOuter{
    background: url(../../../assets/images/Group68.png) right top no-repeat;
    position: relative;
    height: 1097px;
    text-align: right;
    @include respond-to(media-xl) {
        height: 900px;
    }
    @include respond-to(media-xs) {
        height: 800px;
        text-align: left;
    }
    &:after{
        content: '';
        background:url(../../../assets/images/about-shape.png) left top no-repeat;
        left: 0px;
        top: -360px;
        width: 1161px;
        height: 1223px;
        position: absolute;
        @include respond-to(media-xl) {
            top: -146px;
            width: 800px;
            height: 900px;
            background-size: 100%;
        }
        @include respond-to(media-md) {
            top: -54px;
            width: 560px;
            height: 900px;
            background-size: 100%;
            opacity: 0.5;
        }
        @include respond-to(media-sm) {
            width: 100%;
        }
    }
    .aboutRight{
        max-width: 775px;
        padding-top: 0px;
        opacity: 0.9;
        display: inline-block;
        h2{
            font-size: 60px;
            line-height: 76px;
            color: #fff;
            padding-bottom: 30px;
            text-transform: uppercase;
            @include respond-to(media-xs) {
                font-size: 30px;
                line-height: 45px;
                padding-bottom: 10px;
            }
        }
        p{
            font-size: 20px;
            line-height: 31px;
            color: #fff;
            padding-bottom: 40px;
            font-weight: 300;
            @include respond-to(media-xs) {
                font-size: 16px;
                line-height: 25px;
                padding-bottom: 20px;
                font-weight: 400;
            }
        }
    }
}
/**------HowList-------**/

.howList_Outer{
    padding: 30px 0;
    max-width: 1110px;
    margin: 0 auto;
    margin-top: 0px;
    @include respond-to(media-xl) {
        padding: 30px 40px;
    }
    @include respond-to(media-xs) {
        padding: 30px 20px;
    }
    h2{
        font-weight: 400;
        font-size: 65px;
        line-height: 83px;
        padding-bottom: 10px;
        color: #fff;
        @include respond-to(media-sm) {
            font-size: 40px;
            line-height: 63px;
            padding-bottom: 0;
        }
        @include respond-to(media-xs) {
            font-size: 30px;
            line-height: 45px;
        }
    }
    .accordialList{
        padding-bottom: 50px;
        .szh-accordion__item {
            border-bottom: #fff solid 1px;
            padding: 15px 0;
            h3.szh-accordion__item-heading{
                button{
                    background: transparent;
                    color: #fff;
                    border: 0;
                    font-family: 'Montserrat', sans-serif !important;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 34px;
                    width: 100%;
                    text-align: left;
                    position: relative;
                    padding: 5px 0;
                    @include respond-to(media-sm) {
                        font-size: 18px;
                        line-height: 28px;
                    }
                    &:after{
                        background: url(../../../assets/images/down-arrow.png) right center no-repeat;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                        content: '';
                        width: 23px;
                        height: 10px;
                    }
                }
            }
        }
        .szh-accordion__item--expanded{
            button{
                &:after{
                    content: '';
                    transform: rotate(180deg) !important;
                    transition: all 0.5s ease-in-out;
                }
            }
        }
        .szh-accordion__item-content {
            font-size: 18px;
            color: #e5e5e5;
            font-weight: 300;
        }
    }
}

/*-----Partners------*/
.partnersOuter{
    padding: 100px 0;
    @include respond-to(media-xl) {
        padding: 0;
    }
    .partHd{
        padding-bottom: 50px;
        @include respond-to(media-sm) {
            padding-bottom: 20px;
        }
        h2{
            font-weight: 400;
            font-size: 60px;
            line-height: 76px;
            text-align: center;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            background: radial-gradient(91.4% 103.84% at 90.17% 43.19%, #FFD645 0%, #EA04AD 24%, #5E4ADF 51%, #526EF2 63%, #00EDFF 93%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-align: center;
            @include respond-to(media-sm) {
                font-size: 48px;
                line-height: 60px;
            }
            @include respond-to(media-xs) {
                font-size: 28px;
                line-height: 45px;
            }
        }
    }
    .partnerList{
        text-align: center;
        ul{
            li{
                display: inline-block;
                width: 30%;
                margin: 0 1% 85px 1%;
                @include respond-to(media-md) {
                    margin-bottom: 50px;
                }
                @include respond-to(media-xs) {
                    margin-bottom: 20px;
                    width: 48%;
                }
            }
        }
    }
}

/*---Join--*/
.joinOuter{
    background: #0A0A3F;
    .joinBx{
        padding-bottom: 100px;
        text-align: center;
        @include respond-to(media-xs) {
            padding-bottom: 40px;
        }
        h2{
            font-weight: 400;
            font-size: 60px;
            line-height: 76px;
            text-align: center;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            background: radial-gradient(91.4% 103.84% at 90.17% 43.19%, #FFD645 0%, #EA04AD 24%, #5E4ADF 51%, #526EF2 63%, #00EDFF 93%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-align: center;
            padding-bottom: 30px;
            @include respond-to(media-md) {
                font-size: 48px;
                line-height: 65px;
            }
            @include respond-to(media-sm) {
                font-size: 32px;
                line-height: 46px;
            }
            @include respond-to(media-xs) {
                font-size: 20px;
                line-height: 30px;
                padding-top: 20px;
            }
        }
        .joinComBtn{
            width: 600px;
            @include respond-to(media-md) {
                width: 100%;
            }
            margin: 0 auto;
            transition: border-image 0.2s ease-in-out;
            border-image: linear-gradient(to left, rgba(255, 214, 69, 0.7), rgba(234, 4, 173, 0.7), rgba(94, 74, 223, 0.7), rgba(82, 110, 242, 0.7), rgba(0, 237, 255, 0.7)) 1 !important;
            background: transparent !important;
            transition: all 0.5s ease-in-out;
            a{
                padding: 22px 0;
                font-size: 44px;
                line-height: 50px;
                color: #fff;
                display: inline-block;
                @include respond-to(media-md) {
                    padding: 22px 0;
                    font-size: 36px;
                    line-height: 40px;
                }
                @include respond-to(media-sm) {
                    padding: 18px 0;
                    font-size: 28px;
                    line-height: 40px;
                }
                @include respond-to(media-xs) {
                    padding: 10px 0;
                    font-size: 18px;
                    line-height: 40px;
                }
            }
            &:hover{
                transform: scale(1.03) perspective(1000px);
                transition: all 0.5s ease-in-out;
            }
        }
    }
}
.homeFooter{
    text-align: center;
    i{
        display: block;
        img{
            @include respond-to(media-sm) {
                max-width: 150px;
            } 
        }
    }
    span{
        display: block;
        padding: 80px 0;
        font-weight: 300;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #fff;
        @include respond-to(media-sm) {
            padding: 50px 15px;
            font-size: 18px;
        } 
        @include respond-to(media-sm) {
            padding: 30px 15px;
            font-size: 16px;
        }
    }
    a{
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        @include respond-to(media-xs) {
            margin: 0 8px;
        }
        img{
            @include respond-to(media-sm) {
                width: 50px;
            }
            @include respond-to(media-sm) {
                width: 35px;
            }
        }
        &:hover{
            opacity: 0.8;
        }
    }
}
.web{
    @include respond-to(media-sm) {
        display: none;
    }
}
.mob{
    display: none;
    @include respond-to(media-sm) {
        display: block;
    }
}
.ecosystem {
    .ecolistOuter.mob{
        .ecoList1{
            display: block;
            .ecoBx1{
                padding-left: 80px;
                position: relative;
                width: 100%;
                text-align: left;
                padding-bottom: 30px;
                @include respond-to(media-xs) {
                    padding-left: 65px;
                    margin-bottom: 20px;
                    padding-bottom: 0;
                }
                i{
                    position: absolute;
                    left: 0;
                    top: 0;
                    img{
                        width: 60px;
                        @include respond-to(media-xs) {
                           width: 45px;
                        }
                    }
                }
                strong{
                    display: block;
                    min-height: 0;
                    padding: 0 0 10px 0;
                    text-align: left;
                    line-height: normal;
                    br{
                        display: none;
                    }
                }
                small{
                    display: block;
                    font-size: 13px;
                    text-align: left;
                    min-height: 0;
                }
            }
        }
    }
}

.video-react-big-play-button {
    display: none;
}
button.video-react-icon-fullscreen.video-react-fullscreen-control{
    display: none;
}
.video-react-control-bar.video-react-control-bar-auto-hide {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    button.video-react-play-control.video-react-control.video-react-button.video-react-paused{
        overflow: hidden;
        border: 0;
        background: transparent;
        span{
            background: url(../../../assets/images/play-icon.png) left top no-repeat;
            height: 200px;
            width: 150px;
            background-size: 100% 100%;
            text-indent: -200px;
            display: inline-block;
            @include respond-to(media-lg) {
                height: 130px;
                width: 80px;
            }
            @include respond-to(media-xs) {
                height: 80px;
                width: 50px;
            }
        }
    }
    button.video-react-play-control.video-react-control.video-react-button.video-react-playing{
        overflow: hidden;
        border: 0;
        background: transparent;
        display: none;
        span{
            background: url(../../../assets/images/paused-icon.png) left top no-repeat;
            height: 200px;
            width: 100px;
            background-size: 100% 100%;
            text-indent: -200px;
            display: inline-block;
            @include respond-to(media-lg) {
                height: 130px;
                width: 70px;
            }
            @include respond-to(media-xs) {
                height: 50px;
                width: 30px;
            }
        }
    }
}

.gridVideo{
    &:hover{
        button.video-react-play-control.video-react-control.video-react-button.video-react-playing{
            display: block;
        }
    }
}

.roadmapOut{
    margin-top: -300px;
    padding-bottom: 50px;
    @include respond-to(media-sm) {
        margin-top: -230px;
    }
    @include respond-to(media-xs) {
        margin-top: -180px;
    }
}
.roadmapOuter {
    display: flex;
    justify-content: space-between;
    padding: 185px 0 210px 0;
    position: relative;
    @include respond-to(media-sm) {
        padding: 0;
        display: block;
    }
    &:after{
        background: url(../../../assets/images/roadmap-arrow.png) right center no-repeat;
        content: '';
        position: absolute;
        right:-15px;
        top: 50%;
        margin-top: -23px;
        width: 19px;
        height: 21px;
        @include respond-to(media-sm) {
            display: none;
        }
    }
    .roadmapInn{
        width: 20%;
        text-align: center;
        position: relative;
        background: url(../../../assets/images/dashed-background.png) left center repeat-x;
        @include respond-to(media-sm) {
            background:none;
            width: 100%;
            text-align: left;
            padding-left: 90px;
            margin-bottom: 30px;
        }
        @include respond-to(media-xs) {
            padding-left: 55px;
        }
        img{
            @include respond-to(media-md) {
                width: 70px;
            }
            @include respond-to(media-sm) {
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -35px;
            }
            @include respond-to(media-xs) {
                width: 40px;
                margin-top: -20px;
            }
        }
        h2{
            font-size: 30px;
            font-weight: 800;
            color: #fff;
            text-align: center;
            @include respond-to(media-lg) {
                font-size: 24px;
            }
            @include respond-to(media-md) {
                font-size: 20px;
            }
            @include respond-to(media-sm) {
                text-align: left;
            }
            @include respond-to(media-xs) {
                padding-bottom: 5px;
            }
        }
        .roadmapCont{
            background:#1b1936;
            border-radius: 20px;
            box-shadow: #5b6fed 0 0 30px 1px;
            @include respond-to(media-xs) {
                box-shadow: #5b6fed 0 0 20px 1px;
            }
            span{
                border-bottom: rgba(42, 188, 152, 0.2) solid 1px;
                padding: 15px;
                display: block;
                text-align: center;
                color: #fff;
                font-size: 15px;
                line-height: 25px;
                @include respond-to(media-lg) {
                    padding: 10px;
                    font-size: 14px;
                    line-height: 22px;
                }
                @include respond-to(media-md) {
                    padding: 10px 0;
                }
                @include respond-to(media-sm) {
                    padding: 10px 15px;
                    text-align: left;
                }
                @include respond-to(media-xs) {
                    padding: 10px 10px;
                    text-align: left;
                }
                &:last-child{
                    border-bottom: 0;
                }
            }
        }
    }
    .roadbx1{
        img{
            @include respond-to(media-sm) {
                transform: rotate(90deg);
            }
        }
        h2{
            position: absolute;
            top: 110px;
            left: 0;
            right: 0;
            @include respond-to(media-md) {
                top:95px;
            }
            @include respond-to(media-sm) {
                position: relative;
                top: 0;
                left: 0;
                right: auto;
            }
        }
        .roadmapCont{
            position: absolute;
            bottom: 110px;
            left: 0;
            right: 0;
            @include respond-to(media-md) {
                bottom:95px;
            }
            @include respond-to(media-sm) {
                position: relative;
                bottom: 0;
                left: 0;
                right: auto;
            }
        }
    }
    .roadbx2{
        img{
            @include respond-to(media-sm) {
                transform: rotate(-90deg);
            }
        }
        h2{
            position: absolute;
            bottom: 110px;
            left: 0;
            right: 0;
            @include respond-to(media-md) {
                bottom:95px;
            }
            @include respond-to(media-sm) {
                position: relative;
                bottom: 0;
                left: 0;
                right: auto;
            }
        }
        .roadmapCont{
            position: absolute;
            top:110px;
            left: 0;
            right: 0;
            @include respond-to(media-md) {
                top:95px;
            }
            @include respond-to(media-sm) {
                position: relative;
                top: 0;
                left: 0;
                right: auto;
            }
        }
    }
}
.buyBx{
    position: relative;
    span.error {
        position: absolute;
        left: 0;
        bottom: -24px;
        color: #ff00ac;
    }
}