@import "../../../scss/mixin.scss";
.markeplaceBg{
    background: url(../../../assets/images/marketplaceBg.png) center top no-repeat;
    background-size: cover;
}
.marketplace_Inn{
    padding-top: 50px;
    .marketplaceHd{
        padding-bottom: 35px;
        @include respond-to(media-sm) {
            padding-bottom: 20px;
            padding-top: 20px;
        }
        @include respond-to(media-xs) {
            padding-bottom: 10px;
        }
        h2{
            font-size: 80px;
            font-weight: 400;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            line-height: 102px;
            text-align: center;
            color: #fff;
            @include respond-to(media-md) {
                font-size: 60px;
                line-height: 60px;
            }
            @include respond-to(media-sm) {
                font-size: 40px;
                line-height: 48px;
            }
            @include respond-to(media-xs) {
                font-size: 26px;
                line-height: 36px;
            }
        }
    }
    .overallstatus{
        background: rgba(28, 28, 28, 0.6);
        border: 1px solid #545454;
        padding: 20px;
        border-radius: 16px;
        margin-bottom: 20px;
        @include respond-to(media-xs) {
            padding: 15px;
        }
        .statusHdOuter{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            .overallHd{
                font-size: 29px;
                font-weight: 600;
                color: #fff;
                @include respond-to(media-md) {
                    font-size: 20px;
                }
                @include respond-to(media-xs) {
                    font-size: 17px;
                }
            }
            .statusDays{
                span{
                    display: inline-block;
                    line-height: 36px;
                    width: 70px;
                    color: #7e7e7e;
                    font-weight: 600;
                    border-radius: 5px;
                    text-align: center;
                    @include respond-to(media-xs) {
                        font-size: 13px;
                        width: 40px;
                        line-height: 30px;
                    }
                    &.active{
                        background: rgba(104, 106, 205, 0.1);
                        color: #fff;
                    }
                }
            }
        }
        .statusOuter{
            display: flex;
            justify-content: space-between;
            @include respond-to(media-sm) {
                display: block;
            }
            .statusBx{
                width: 32%;
                @include respond-to(media-sm) {
                    width: 100%;
                    padding-bottom: 25px;
                }
                .statusHd{
                    margin-bottom: 20px;
                    position: relative;
                    padding-left: 50px;
                    color: #fff;
                    i{
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        left: 0;
                    }
                    span{
                        display: block;
                        font-size: 15px;
                        text-transform: uppercase;
                        @include respond-to(media-md) {
                            font-size: 13px;
                        }
                    }
                    strong{
                        display: block;
                        font-size: 20px;
                        @include respond-to(media-md) {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
}
.recentListing {
    background: rgba(28, 28, 28, 0.6);
    border: 1px solid #545454;
    padding: 20px;
    border-radius: 16px;
    margin-bottom: 20px;
    @include respond-to(media-xs) {
        padding: 15px;
    }
    .recentListingHd {
        font-size: 24px;
        color: #fff;
        font-weight: 600;
        @include respond-to(media-md) {
            font-size: 20px;
        }
    }
}
.slideItemBx{
    background: linear-gradient(217.15deg, #5062DB 2.93%, #3D3FA3 97.72%);
    border: 1px solid #686ACD;
    border-radius: 20px;
    height: 365px;
    padding: 10px;
    .slideTitle{
        border-bottom: 1px solid #686ACD;
        padding-bottom: 15px;
        margin-bottom: 15px;
        color: #fff;
        padding-top: 10px;
        strong{
            display: block;
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
            text-transform: capitalize;
            padding-bottom: 5px;
        }
        span{
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-transform: capitalize;
        }
    }
    .likeOuter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .likeLeft{
            font-size: 18px;
            color: #fff;
            font-weight: 600;
            img{
                width: 28px;
                height: 28px;
                display: inline-block;
                vertical-align: top;
                margin-right: 8px;
            }
        }
        .likeright{
            font-size: 17px;
            color: #fff;
            svg{
                font-size: 20px;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
}

.tabOuterBx {
    position: relative;
    margin-top:20px;
    @include respond-to(media-sm) {
        margin-top: 10px;
    }
    ul.nav.nav-tabs {
        position: absolute;
        left: 290px;
        top: -60px;
        border: 0;
        @include respond-to(media-md) {
            left: 210px;
            top: -52px;
        }
        @include respond-to(media-sm) {
            left: 0;
            top: 0;
            position: relative;
            margin-bottom: 20px !important;
        }
        a.nav-link {
            font-size: 16px;
            color: #7E7E7E;
            font-weight: 600;
            border: 0;
            @include respond-to(media-md) {
                font-size: 14px;
                padding: 7px 5px !important;
            }
        }
        a.active.nav-link {
            border-radius: 5px;
            background: transparent !important;
            color: #fff;
            border: #fff solid 1px;
            border-image: linear-gradient(to left, rgba(255, 214, 69, 0.7), rgba(234, 4, 173, 0.7), rgba(94, 74, 223, 0.7), rgba(82, 110, 242, 0.7), rgba(0, 237, 255, 0.7)) 1 !important;
        }
    }
    .owl-dots{
        display: none;
    }
    .owl-nav {
        position: absolute;
        top: -65px;
        right: 0;
        color: #fff;
        @include respond-to(media-md) {
            top: -55px;
        }
        @include respond-to(media-sm) {
            top: -105px;
        }
        @include respond-to(media-xs) {
            top: 0;
            position: relative;
            margin-top: 10px;
            text-align: center;
        }
        button{
            background:rgba(104, 106, 205, 0.1) !important;
            border-radius: 5px;
            width: 50px;
            height: 50px;
            margin-left: 10px;
            font-size: 26px !important;
            font-weight: 800 !important;
            @include respond-to(media-md) {
                height: 40px;
            }
        }
    }
}
.mpFoot{
    padding-top: 70px;
}