@import "../../../scss/mixin.scss";
.nftSrchOuter {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @include respond-to(media-xs) {
        display: block;
    }
    .nftSrchBx {
        width: 70%;
        position: relative;
        @include respond-to(media-md) {
            width: 65%;
        }
        @include respond-to(media-sm) {
            width: 60%;
        }
        @include respond-to(media-xs) {
           width: 100%;
           margin-bottom: 10px;
        }
        input{
            width: 100%;
            height: 56px;
            background: rgba(147, 140, 188, 0.2);
            border-radius: 5px;
            border: 0;
            box-shadow: none !important;
            color: #fff;
        }
        button{
            position: absolute;
            right: 5px;
            top: 5px;
            background: transparent;
            border: 0;
            font-size: 20px;
        }
    }
    .nftlist {
        width: 23%;
        @include respond-to(media-xs) {
            display: inline-block;
            width: 75%;
            margin-right: 5%;
        }
        select{
            width: 100%;
            height: 56px;
            background: rgba(147, 140, 188, 0.2) !important;
            border-radius: 5px;
            border: 0;
            box-shadow: none !important;
            color: #fff;
        }
    }
    .nftrefresh{
        @include respond-to(media-xs) {
            display: inline-block;
        }
        button{
            background: rgba(147, 140, 188, 0.2) !important;
            color: #fff;
            width: 56px;
            height: 56px;
            border: 0;
            border-radius: 5px;
            color: #fff;
            font-size: 32px;
            padding: 0;
            text-align: center;
            &:hover{
                background: #0099ff !important;
            }
        }
    }

}
.allnftOuter{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    @include respond-to(media-sm) {
        display: block;
    }
    .filterLeft{
        width: 315px;
        padding: 20px;
        background: rgba(28, 28, 28, 0.6);
        border: #545454 solid 1px;
        border-radius: 16px;
        @include respond-to(media-lg) {
            width: 270px;
            padding: 15px;
        }
        @include respond-to(media-sm) {
            width: 100%;
        }
        .fltHd{
            font-size: 24px;
            color: #7A84F2;
            font-weight: 600;
            padding-bottom: 10px;
        }
        .accordialBx{
            .accordion{
                .accordion-item{
                    background: transparent !important;
                    border: 0 !important;
                    border-bottom: #545454 solid 1px !important;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    &:last-child{
                        border-bottom: 0 !important;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                    .accordion-header{
                        button{
                            background: transparent !important;
                            color: #fff;
                            padding: 10px 0;
                            border: 0;
                            box-shadow: none;
                            &:after{
                                background-image: url(../../../assets/images/down.png);
                            }
                        }
                    }
                    .accordion-body{
                        padding: 0;
                        .form-check{
                            padding: 6px 0;
                            label{
                                color: #fff;
                                line-height: 40px;
                                input{
                                    width: 22px;
                                    height: 22px;
                                    border: #545454 solid 1px;
                                    margin-right: 10px;
                                    display: inline-block;
                                    vertical-align: top;
                                    margin-top: 9px;
                                    margin-left: 0;
                                }
                                i{
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    display: inline-block;
                                    vertical-align: top;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .listingRight{
        width: calc(100% - 335px);
        padding: 20px;
        background: rgba(28, 28, 28, 0.6);
        border: #545454 solid 1px;
        border-radius: 16px;
        @include respond-to(media-lg) {
            width: calc(100% - 285px);
        }
        @include respond-to(media-sm) {
            width: 100%;
            margin-top: 20px;
        }
        .listOutBx{
            @include respond-to(media-xs) {
                text-align: center;
            }
        }
        .slideItemBx{
            width: 23.5%;
            margin-right: 2%;
            display: inline-block;
            margin-bottom: 20px;
            height: auto;
            @include respond-to(media-md) {
                width: 48%;
                margin-right: 4%;
            }
            @include respond-to(media-xs) {
                width: auto;
                margin-right: 0%;
            }
            &:nth-child(4n){
                margin-right: 0;
            }
            &:nth-child(2n){
                @include respond-to(media-md) {
                    margin-right: 0;
                }
            }
        }
        
    }
}
.moreList{
    padding-top: 10px;
   button{
        width: 100%;
        border-radius: 5px;
        background: transparent !important;
        color: #fff;
        height: 50px;
        font-size: 16px;
        border: #fff solid 1px;
        border-image: linear-gradient(to left, rgba(255, 214, 69, 0.7), rgba(234, 4, 173, 0.7), rgba(94, 74, 223, 0.7), rgba(82, 110, 242, 0.7), rgba(0, 237, 255, 0.7)) 1 !important;
   }         
}
