@import "../../../scss/mixin.scss";

.balanceLeft{
    background: url(../../../assets/images/transBg.png) left top no-repeat;
    background-size: 100% 100%;
    max-width: 550px;
    width: 100%;
    padding: 70px;
    min-height: 550px;
    @include respond-to(media-xs) {
        min-height: 400px;
        padding: 70px 30px;
    }
}
.gspBalance{
    padding-top: 50px;
    @include respond-to(media-xs) {
       padding-top: 0;
    }
}
.gspValue {
    text-align: center;
    color: #fff;
    border-bottom: #fff dashed 1px;
    padding-bottom: 35px;
    padding-top: 10px;
    @include respond-to(media-lg) {
        padding-bottom: 15px;
        padding-top: 0px;
    }
    i{
        width: 95px;
        display: inline-block;
        padding-bottom: 5px;
        @include respond-to(media-xs) {
            width: 60px;
        }
    }
    span{
        display: block;
        font-size: 18px;
        padding-top: 5px;
    }
    strong{
        display: block;
        font-size: 38px;
        @include respond-to(media-sm) {
            font-size: 32px;
        }
        @include respond-to(media-xs) {
            font-size: 25px;
        }
    }
    a{
        color: #fff;
    }
}
.finalValue{
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding-top: 30px;
    @include respond-to(media-lg) {
        padding-top: 20px;
    }
    @include respond-to(media-xs) {
        font-size: 24px;
    }
    img{
        width: 50px;
        display: inline-block;
        margin-right: 5px;
        @include respond-to(media-xs) {
            width: 30px;
        }
    }
}
.transList{
    width: 480px;
    padding-top: 20px;
    @include respond-to(media-md) {
        width: 390px;
        margin-left: -30px;
    }
    @include respond-to(media-sm) {
        width: 470px;
    }
    @include respond-to(media-xs) {
        width: 100%;
        margin-left: 0;
    }
    ul{
        li{
            background: url(../../../../src/assets/images/transition-bg.png) left top no-repeat;
            background-size: 100% 100%;
            padding: 60px 70px;
            min-height: 140px;
            color: #fff;
            font-size: 24px;
            margin-top: -20px;
            @include respond-to(media-md) {
                padding: 60px 50px;
            }
            @include respond-to(media-xs) {
                padding: 40px 50px;
                font-size: 18px;
                min-height: 100px;
            }
            a{
                color: #fff;  
            }
            img{
                display: inline-block;
                margin-right: 30px;
                @include respond-to(media-md) {
                    margin-right: 15px;
                }
                @include respond-to(media-xs) {
                    max-width: 50px;
                }
            }
            &:hover{
                a{
                   color: #f89665; 
                }
            }
        }
    }
}

.gspOutBx{
    max-width: 1160px;
    margin: 0 auto;
}