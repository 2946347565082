@import "../../../scss/mixin.scss";
.ConnetBx{
    .notConnect_Outer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
            font-size: 14px;
            background: -webkit-linear-gradient(#78909C, #EA04AD);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include respond-to(media-xs) {
                font-size: 12px;
            }
        }
        small{
            font-size: 14px;
            color: #fff;
            @include respond-to(media-xs) {
                font-size: 12px;
            }
            i{
                display: inline-block;
                width: 24px;
                height: 24px;
                background: linear-gradient(90deg, #78909C 0%, #EA04AD 94.27%);
                border-radius: 50%;
                vertical-align: middle;
                margin-left: 5px;
                @include respond-to(media-xs) {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    .buyBx{
        padding-top: 45px;
        @include respond-to(media-md) {
            padding-top: 30px;
        }
        @include respond-to(media-xs) {
            padding-top: 20px;
        }
        label{
            display: block;
            font-size: 16px;
            padding-bottom: 5px;
            color: #fff;
        }
        .gspFild{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: rgba(147, 140, 188, 0.2);
            border-radius: 16px;
            padding-right: 10px;
            .gspInn_Left{
                .form-control{
                    height: 56px;
                    background: transparent;
                    border: 0;
                    box-shadow: none !important;
                    color: #fff;
                    font-size: 18px;
                    @include respond-to(media-md) {
                        max-width: 100px;
                    }
                }
            }
            .gspInn_Right{
                span {
                    display: inline-block;
                    background: rgba(243, 212, 83, 0.7);
                    border-radius: 16px;
                    color: #000;
                    line-height: 26px;
                    width: 44px;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    @include respond-to(media-xs) {
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
                i {
                    display: inline-block;
                    width: 24px;
                    margin: 0 10px;
                    @include respond-to(media-xs) {
                        width: 20px;
                        margin: 0 5px;
                    }
                }
                small{
                    color: #fff;
                }
            }
        }
    }
}
.buygsp{
    button{
        height: 53px;
        border: #fff solid 2px;
        background: transparent;
        width: 100%;
        border-radius: 15px;
        font-size: 16px;
        @include respond-to(media-xs) {
            height: 45px;
        }
        &:hover{
            background: #e307af;
            border: #e307af solid 2px;
        }
    }
}
.buygspBx {
    margin: 0 auto !important;
}
.pb-65{
    padding-bottom: 65px;
    @include respond-to(media-lg) {
        padding-bottom: 40px;
    }
    @include respond-to(media-xs) {
        padding-bottom: 20px;
    }
}
.Inscont {
    text-align: center;
    padding-bottom: 10px;
    font-size: 16px;
    color: #e307af;
    svg {
        position: relative;
        top: -2px;
        right: 2px;
    }
}
.headRight{
    > button{
        @include respond-to(media-sm) {
            display: none;
        }
    }
}
.mobnavBtns{
    display: none;
    @include respond-to(media-sm) {
        display: block;
    }
    button{
        margin-left: 0 !important;
        margin-bottom: 10px;
        width: 100% !important;
    }
}
.loaderOuter{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    .loaderInn{
        fill: #e307af !important;
        height: 100px !important;
        width: 150px !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}



.signupHd span {
    display: block;
    font-size: 33px;
    text-align: center;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    @include respond-to(media-xs) {
        font-size: 24px;
    }
}

.ConnetBx.SIgnupOutr .buyBx{
    padding-top: 20px !important;
    @include respond-to(media-xs) {
        padding-top: 15px !important;
    }
}
.signupPadd{
    padding: 60px 100px 0 100px !important;
    height: 700px;
    @include respond-to(media-md) {
        padding: 60px 50px  0 50px !important;
    }
    @include respond-to(media-xs) {
        padding: 35px 25px  0 25px !important;
        height: 590px;
    }
    
}
.ConnetBx.SIgnupOutr .buyBx.pb-65 {
    padding-bottom: 30px !important;
    @include respond-to(media-xs) {
        padding-bottom: 20px  !important;
    }
}

.signupButton{
    
}